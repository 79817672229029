<template>
  <div>
    <content-background-cover
      background-color='#FDFCFD'
      class='z-0'
      :top-offset='0' />
    <main-hero-section />
    <main-important-dates
      :important-dates='importantDates'
      :sidebar-bg-container-configs='sidebarConfigs'
      main-card-width='20rem' />
    <main-sponsors class='mt-8' />
  </div>
</template>

<script>
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import MainHeroSection        from '@/components/MainHeroSection.vue'
import MainImportantDates     from '@/components/MainImportantDates.vue'
import MainSponsors           from '@/components/apsr2022/MainSponsors.vue'


export default {
  name: 'Main',
  components: {
    ContentBackgroundCover,
    MainHeroSection,
    MainImportantDates,
    MainSponsors,
  },
  data () {
    return {
      sidebarConfigs: {
        title: 'Important Dates',
        height: '580px',
        barStyle: 'linear-gradient(151.84deg, #596687 10.82%, #4B5671 98.95%); color: #FFF;'
      },
      importantDates: [
        {
          monthString: 'Dec 2022',
          dayString: '12',
          title: '[Registration]\nAfter Standard Registration Open',
          description: ''
        },
        {
          monthString: 'Dec 2022',
          dayString: '19',
          title: '[On-demand Platform]\nOpen',
          description: ''
        },
        {
          monthString: 'Jan 2023',
          dayString: '31',
          title: '[On-demand Platform]\nClose',
          description: ''
        },
      ],
    }
  },
}
</script>
