<template>
  <div class='flex flex-col lg:flex-row justify-start px-8 lg:px-0 lg:justify-center lg:items-center relative pt-8 pb-16 gap-x-36 gap-y-16 lg:gap-y-0'>
    <content-background-cover
      background-color='#f6f6f6'
      class='z-0' />
    <div class=''>
      <h3 class='uppercase text-gray-900 text-lg font-semibold mb-4'>Hosted by</h3>
      <div class='flex flex-row justify-center lg:justify-start items-center gap-x-24'>
        <img src='https://apsr2022.org/img/main/hostedImg1.png' class='w-24'>
        <img src='https://apsr2022.org/img/main/hostedImg2.png' class='w-24'>
      </div>
    </div>
    <div class=''>
      <h3 class='uppercase text-gray-900 text-lg font-semibold mb-4'>Sponsored by</h3>
      <div class='flex flex-col lg:flex-row justify-start items-start lg:items-center gap-x-12 gap-y-12'>
        <img src='https://apsr2022.org/img/main/hostedImg3.png' class='h-20 lg:h-24'>
        <img src='https://apsr2022.org/img/main/hostedImg4.png' class='h-16 lg:h-20'>
      </div>
    </div>

  </div>
</template>

<script>
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'

export default {
  name: 'MainSponsors',
  components: {
    ContentBackgroundCover,
  },
}
</script>
